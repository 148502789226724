import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { getInvoiceDetail, getPOHeader, postInvoice, updateInvoices } from "../../services/accounts";
import { log } from "../../utils/logger";
import { isEmpty } from 'lodash';
import Footer from "../../global_components/footer";
import CusNavBar from "../../global_components/navbar";
import Header from "../../global_components/header";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { MdArrowBack } from 'react-icons/md';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
} from "@mui/material";
import {
    Card,
    CardContent,
    FormControl, Grid,
    InputLabel,
    MenuItem,
    Autocomplete,
    TextField,
    Typography,
    FormControlLabel,
    List,
    ListItem,
    ListItemText,
    Checkbox, Accordion, AccordionSummary, AccordionDetails
} from "@mui/material";
import Select from '@mui/material/Select';
import Option from '@mui/joy/Option';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ArrowDropDownIcon, LocalizationProvider } from "@mui/x-date-pickers";
import { ArrowRight } from "react-bootstrap-icons";
import { API_URL } from "../../constants";
import CusAlert from "../../global_components/alert";
import NumberFormat from "react-number-format";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteInvoicePopup from "../popups/delete_invoice_popup";
import { useSnackbar } from "notistack";
import TopicIcon from '@mui/icons-material/Topic';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InvoicesList from "./fsi_list";
import CloseRounded from '@mui/icons-material/CloseRounded';
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";

const SummaryDialog = ({ open, handleClose, invoicesToBeUpdated, handleConfirm }) => {
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Summary of Changes</DialogTitle>
            <DialogContent dividers>
                <Typography variant="body1">
                    The following unit prices will be updated:
                </Typography>
                <List>
                    {invoicesToBeUpdated.map((invoice, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                primary={
                                    <>
                                        • Unit price of <strong>{invoice.description}</strong> will update
                                        from <strong>${invoice.unit_price_po}</strong> to <strong>${invoice.unit_price_line}</strong>.
                                    </>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    style={{ backgroundColor: "red", color: "white", fontWeight: "bold" }}
                >
                    Edit More
                </Button>
                <Button
                    onClick={handleConfirm}
                    style={{ backgroundColor: "green", color: "white", fontWeight: "bold" }}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};


function CloseIcon() {
    return null;
}

function ClearIcon() {
    return null;
}

function InvoiceDetail(props) {
    const navigate = useNavigate();
    const routeParams = useParams();

    const [sellerName, setSellerName] = useState("");
    const [index, setIndex] = useState();

    const [open, setOpen] = useState(false);

    // Example invoicesToBeUpdated array
    const invoicesToBeUpdated = [
        {
            inv: 0,
            description: "ENVELOPE PTFE FULL-FACE GASKET",
            unit_price_po: 10.19,
            unit_price_line: "10.20",
        },
        // Add more invoices if needed
    ];

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = async () => {
        await submit();
        setOpen(false); // Close the dialog after confirming
    };
    const [lineunit, setLineunit] = useState();
    const [pounit, setPounit] = useState();


    const [storeInvoice, setStoreInvoice] = useState([]);
    const [invoice, setInvoice] = useState([]);
    console.log(invoice);

    const handleSend = () => {
        const updatedLineItems = [...invoice.line_items];
        updatedLineItems[index].update_poline = true;
        setOpen(false)
    };

    const [invoicesToUpdate, setInvoicesToUpdate] = useState([]);

    const handleCheckboxChange = (event, inv, description, unit_price) => {
        if (event.target.checked) {

            const unit_price_line = invoice.line_items[inv].unit_price
            // Create the object to add to the state
            const newInvoiceToUpdate = { inv, description, unit_price_po: unit_price, unit_price_line };

            // Add the object to the array
            setInvoicesToUpdate((prevState) => [...prevState, newInvoiceToUpdate]);

        } else {

            // Remove the object from the array (filter out the unchecked item)
            setInvoicesToUpdate((prevState) =>
                prevState.filter((item) => item.inv !== inv)
            );
        }
    };

    const handleSubmitPopupOpen = () => {
        setOpen(true)
    }

// UseEffect to update invoice.line_items based on invoicesToUpdate
useEffect(() => {
    // If `invoice.line_items` exists and `invoicesToUpdate` changes, update line items
    if (invoice?.line_items?.length > 0) {
      const updatedLineItems = invoice.line_items.map((lineItem, index) => {
        // If this line item's `inv` exists in `invoicesToUpdate`, set `update_poline` to true
        const shouldUpdate = invoicesToUpdate.some((update) => update.inv === index);
        
        return {
          ...lineItem,
          update_poline: shouldUpdate, // Update the `update_poline` field accordingly
        };
      });
  
      // Update the invoice state with the modified line items
      setInvoice((prevInvoice) => ({
        ...prevInvoice,
        line_items: updatedLineItems,
      }));
    }

        console.log(invoice)
    }, [invoicesToUpdate]);


    const [poDetailLoading, setPoDetailLoading] = useState(true);
    const [poNotFound, setPoNotFound] = useState(false);
    const [poEmpty, setPoEmpty] = useState(false);
    const [PO, setPO] = useState([]);
    const [POItems, setPOItems] = useState([]);
    const [accountNumberOption, setAccountNumberOption] = useState([]);
    const [vendorOption, setVendorOption] = useState([]);

    const [poItemsOption, setPoItemsOption] = useState([]);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showFailureMessage, setShowFailureMessage] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [loadingSave, setLoadingSave] = useState(false)
    const [loadingInvoice, setLoadingInvoice] = useState(false)
    const [showDeletionPopup, setShowDeletionPopup] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const action = useRef(null);
    const [totalBudgetAllocation, setTotalBudgetAllocation] = useState();

    const [invItems, setInvItems] = useState([]);
    const [invLen, setInvLen] = useState(0);
    const [invAmount, setInvAmount] = useState(0);

    const [markings, setMarkings] = useState([])
    const [mappings, setMappings] = useState([])

    // UseEffect to ensure only the most recent marking per line is kept
    useEffect(() => {
        const uniqueMarkings = markings.reduce((acc, current) => {
            const existingIndex = acc.findIndex(marking => marking.rec === current.rec);
            if (existingIndex >= 0) {
                acc[existingIndex] = current;  // Replace the old marking with the most recent one
            } else {
                acc.push(current);  // Add new marking if it's not already in the list
            }
            return acc;
        }, []);

        // Only update the state if there are changes
        if (JSON.stringify(uniqueMarkings) !== JSON.stringify(markings)) {
            setMarkings(uniqueMarkings);
        }

        const uniqueMappings = mappings.reduce((acc, current) => {
            const existingIndex = acc.findIndex(mapping => mapping.inv === current.inv);
            if (existingIndex >= 0) {
                acc[existingIndex] = current;  // Replace the old marking with the most recent one
            } else {
                acc.push(current);  // Add new marking if it's not already in the list
            }
            return acc;
        }, []);

        // Only update the state if there are changes
        if (JSON.stringify(uniqueMappings) !== JSON.stringify(mappings)) {
            setMappings(uniqueMappings);
        }
    }, [mappings, markings]);


    async function fetchInvoiceDetails() {
        try {
            // Assuming getInvoiceDetail returns a promise that resolves to the fetch response
            let response1 = await getInvoiceDetail(routeParams?.id);

            if (!response1.ok) {
                throw new Error(`HTTP error! status: ${response1.status}`);
            }

            const result1 = await response1.json();  // Properly wait for the JSON conversion
            setInvItems(result1.invoice_items)
            setInvLen(result1.invoice_items.length)
            setInvAmount(result1.invoice_details.invoice_amount)

        } catch (error) {
            console.error("Failed to fetch invoice details:", error);
            // Handle errors, e.g., by showing a message to the user
        }
    }

    useEffect(() => {
        fetchInvoiceDetails();
    }, [])



    const sxTemp = {
        "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#696969",
        }
    }
    const setInvoiceDetail = async (id) => {
        setLoadingInvoice(true);
        let response = await getInvoiceDetail(id);
        if (response.status === 200) {
            const result = await response.json();
            setSellerName(result.invoice_details.seller_name)
            setInvoice(result);
            result.invoice_items.forEach(function (item) {
                if (item.acc_number === null || item.acc_number === undefined || item.acc_number === '') {
                    item.acc_number = result.invoice_details.client_number;
                }
            });
            setStoreInvoice(result['invoice_details']);
            setInvoice(prevProject => ({
                ...prevProject,
                line_items: result['invoice_items']
            }));
        } else {
            const result = await response.json();
            enqueueSnackbar(result, { variant: "error" });
            return 0;
        }
        setLoadingInvoice(false);
    }

    const setPODetail = async (po_number) => {
        setPoNotFound(false);
        setPoEmpty(false);
        setPoDetailLoading(true);
        if (po_number === '' || po_number === null || po_number === undefined) {
            setPoEmpty(true);
            setPO({
                po_date: "",
                supplier_name: "",
                vendor_id: "",
                po_amount: "",
                gl_account: "",
                po_number: "",
            })
            setPOItems([])
        } else {
            const response = await getPOHeader(po_number);
            if (response.status === 200) {
                const result = await response.json();
                setPO(result.po);
                if (result.po.length === 0) {
                    setPoNotFound(true);
                    setPoEmpty(false);
                    setPO({
                        po_date: "",
                        supplier_name: "",
                        vendor_id: "",
                        po_amount: "",
                        gl_account: "",
                        po_number: "",
                    })
                    setPOItems([])
                } else {
                    setPOItems(result.po_items);
                }
            } else {

                const result = await response.json();
                enqueueSnackbar(result, { variant: "error" });
                return 0;
            }
        }


        setPoDetailLoading(false);
    }

    const getPODetail = () => {
        setPoDetailLoading(true);
        const updatedLineItems = [...invoice.line_items];
        updatedLineItems.forEach(item => {
            item.matching_po_line = null;
        });
        setInvoice({
            ...invoice,
            line_items: updatedLineItems
        });

        if (storeInvoice.po_number === '') {
            setPoEmpty(true);
            setPoNotFound(false);
            setPO({
                po_date: "",
                supplier_name: "",
                vendor_id: "",
                po_amount: "",
                gl_account: "",
                po_number: "",
            })
            setPOItems([])
        } else {
            setPODetail(storeInvoice.po_number);
        }
        setPoDetailLoading(false);
    }

    const handleAddLineItem = () => {
        const lastItem = invoice?.line_items[invoice?.line_items.length - 1];
        const lineNumber = lastItem.line_number;
        const newLineItem = {
            id: 0,
            line_number: lineNumber + 1,
            description: "",
            amount: 0,
            acc_number: storeInvoice?.client_number,
            product_id: null,
            quantity: 0,
            unit_price: "",
            line_price: "",
            matching_po_line: null,
        };
        // setLineItems(prevSources => [...prevSources, newLineItem]);
        setInvoice(prevProject => ({
            ...prevProject,
            line_items: [...prevProject.line_items, newLineItem]
        }));
    };
    const handleRemoveLineItem = (index) => {
        const updatedSources = [...invoice.line_items];
        const updatedSourcesInv = [...invoice.invoice_items];
        updatedSources.splice(index, 1);
        updatedSourcesInv.splice(index, 1);

        setInvItems(updatedSourcesInv)

        // setLineItems(updatedSources);
        setInvoice(prevProject => ({
            ...prevProject,
            line_items: updatedSources,
            invoice_items: updatedSourcesInv
        }));
    };

    const getApiData = () => {
        let api_data = invoice;
        api_data["invoice_details"] = storeInvoice
        if (isNaN(parseFloat(api_data["invoice_details"]["invoice_amount"]))) {
            api_data["invoice_details"]["invoice_amount"] = 0
        }
        return api_data;
    };

    const submit = async () => {
        setLoadingSubmit(true);
        let api_data = getApiData();
        api_data["invoice_details"]["seller_name"] = sellerName;
        const save_response = await updateInvoices(api_data);
        if (save_response === 200) {
            let invoice_response = await getInvoiceDetail(routeParams.id);
            if (invoice_response.status === 200) {
                const invoice_result = await invoice_response.json();
                let api_data = invoice_result;
                api_data["line_items"] = invoice_result['invoice_items'];
                if (isNaN(parseFloat(api_data["invoice_details"]["invoice_amount"]))) {
                    api_data["invoice_details"]["invoice_amount"] = 0
                }
                const response = await postInvoice(api_data);
                if (response.status === 200) {
                    enqueueSnackbar('The invoice have been submitted. This page will be closed after 3 seconds.', { variant: "success" });
                    setTimeout(() => window.close(), 3000);
                    setLoadingSubmit(false);
                } else {
                    const result = await response.json();
                    enqueueSnackbar(result, { variant: "error" });
                    setLoadingSubmit(false);
                }
            } else {
                const result = await save_response.json();
                enqueueSnackbar(result, { variant: "error" });
            }

        } else {
            enqueueSnackbar('An error has been encountered while saving invoice.', { variant: "error" });
            setLoadingSubmit(false);
        }
    }

    const save = async () => {
        setLoadingSave(true);
        let api_data = invoice;
        api_data["invoice_details"] = storeInvoice
        api_data["invoice_details"]["seller_name"] = sellerName;

        if (isNaN(parseFloat(api_data["invoice_details"]["invoice_amount"]))) {
            api_data["invoice_details"]["invoice_amount"] = 0
        }

        const response = await updateInvoices(api_data);
        if (response === 200) {
            setLoadingSave(false);
            await setInvoiceDetail(routeParams.id);
            enqueueSnackbar('The changes have been saved.', { variant: "success" });

            window.location.reload();
        } else {
            enqueueSnackbar('An error has been encountered while saving invoice.', { variant: "error" });
            setLoadingSave(false);
        }
    }
    const deleteInvoice = () => {
        setShowDeletionPopup(false);
        window.close()
    }

    useEffect(async () => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }
        if (routeParams?.id) {
            const token = localStorage.getItem('userdata');
            const response_gl = await fetch(`${API_URL}/account_number_options/`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token,
                    "Content-Type": "application/json",
                },
            }).catch(() => {
                enqueueSnackbar('An error has been encountered while getting account number options.', { variant: "error" });
            });
            if (response_gl.status === 200) {
                const data = await response_gl.json()
                setAccountNumberOption(data);
            }

            const response_po_items = await fetch(`${API_URL}/ap/po_items/`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token,
                    "Content-Type": "application/json",
                },
            }).catch(() => {
                enqueueSnackbar('An error has been encountered while getting po items options.', { variant: "error" });
            });
            if (response_po_items.status === 200) {
                const data = await response_po_items.json()
                setPoItemsOption(data);
            }

            const response_vendor_id = await fetch(`${API_URL}/vendor_id_options/`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token,
                    "Content-Type": "application/json",
                },
            }).catch(() => {
                enqueueSnackbar('An error has been encountered while getting vendor id options.', { variant: "error" });
            });
            if (response_vendor_id.status === 200) {
                const data = await response_vendor_id.json()
                var resultData = [];
                data.forEach((item) => {
                    resultData.push({ label: item.label, value: item.value })
                });
                setVendorOption(resultData);
            } else {
                log('setVendorOption error,', response_vendor_id);
            }

            await setInvoiceDetail(routeParams?.id);
        } else {
            navigate("/invoices");
        }

    }, []);

    const getQuantityValue = (index) => {
        let value = POItems[index].quantity;
        if (POItems?.length !== 0) {
            if (POItems[index].quantity_shipped != null)
                value -= POItems[index].quantity_shipped;
        }
        return value;
    }

    useEffect(async () => {
        if (storeInvoice?.length !== 0 && PO.length === 0) {
            await setPODetail(storeInvoice.po_number);
        }
    }, [storeInvoice]);

    useEffect(() => {
        let sumBudget = 0;
        invoice.line_items?.forEach((item, index) => {
            sumBudget = sumBudget + parseFloat(item.line_price || 0);
        });
        log("invoiceeee", invoice)
        setTotalBudgetAllocation(sumBudget.toFixed(2));
        setInvoice({
            ...invoice,
            invoice_amount: invAmount,
        });
    }, [invoice.line_items]);

    // useEffect(async ()=>{
    //     const updatedLineItems = invoice.line_items.map(item => {
    //         if (item.line_price !== "") {
    //             return { ...item, line_amount: item.line_price };
    //         }
    //         return item;
    //     });
    //     setInvoice({ ...invoice, line_items: updatedLineItems });
    // },[invoice.line_items]);

    useEffect(async () => {
        if (POItems?.length !== 0) {
            let matching_po_lines = invoice.line_items.filter(function (el) {
                return el.matching_po_line !== null
            });
            for (let i = 0; i < matching_po_lines.length; i++) {
                var value = matching_po_lines[i].matching_po_line
                var selected_po_line_index = POItems.map(function (e) {
                    return e.id;
                }).indexOf(parseInt(value));
                POItems[selected_po_line_index].received = true;
            }
        }
    }, [POItems]);

    const [filter, setFilter] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(vendorOption);

    useEffect(() => {
        // Filter options based on input
        if (filter !== "") {
            const filtered = vendorOption.filter(option => option.label.toLowerCase().includes(filter.toLowerCase()));
            setFilteredOptions(filtered);
        } else {
            setFilteredOptions(vendorOption);
        }
    }, [filter, vendorOption]);

    // Utility function to check equality based on precision
const arePricesEqual = (price1, price2) => {
    if (price1 === 'N/A' || price2 === 'N/A'){
        return true;
    }
    // Ensure the values are numbers, defaulting to 0 if not
    const numPrice1 = Number(price1) || 0;
    const numPrice2 = Number(price2) || 0;
    // Round both prices to one decimal place
    const roundedPrice1 = Math.round(numPrice1 * 10) / 10;
    const roundedPrice2 = Math.round(numPrice2 * 10) / 10;
    
    // Compare the rounded values first
    if (roundedPrice1 !== roundedPrice2) {
        return false; // They are unequal if the rounded values differ
    }
    // If they are equal after rounding, check if the original prices differ more precisely
    return numPrice1.toFixed(2) === numPrice2.toFixed(2);
};


    return (
        <>
            <CusNavBar />
            <Header text="Invoice Details" />
            {(routeParams?.id !== '0' && storeInvoice?.length !== 0) &&
                <div>
                    <Row>
                        <Col>
                            <Card sx={{ width: '100%', height: '100%' }}>
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="div">
                                        Invoice Header
                                    </Typography>
                                    <hr className="fs-1" />
                                    <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                                        <NumberFormat
                                            disabled={invoice.status === "posted" || invoice.status === "paid"}
                                            sx={sxTemp}
                                            label="Invoice #"
                                            customInput={TextField}
                                            value={storeInvoice.invoice_number}
                                            error={storeInvoice.invoice_number === 0 || storeInvoice.invoice_number === ""}
                                            helperText={storeInvoice.invoice_number === 0 || storeInvoice.invoice_number === "" ? "Invoice Number cannot be empty" : ""}
                                            isNumericString
                                            allowNegative={false}
                                            onChange={(e) => {
                                                setStoreInvoice({
                                                    ...storeInvoice,
                                                    invoice_number: e.target.value,
                                                })
                                            }} />
                                    </FormControl>
                                    <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label={<span>Invoice Date</span>}
                                                disabled={invoice.status === "posted" || invoice.status === "paid"}
                                                sx={sxTemp}
                                                startDate={dayjs(storeInvoice.invoice_date, "YYYY-MM-DD")}
                                                endDate={dayjs(storeInvoice.invoice_date, "YYYY-MM-DD")}
                                                format="MM-DD-YYYY"  // This specifies the format in which the date should be displayed in the UI
                                                value={dayjs(storeInvoice.invoice_date, "YYYY-MM-DD")}
                                                onChange={(date) => {
                                                    // The date returned from `date` is in the 'MM-DD-YYYY' format
                                                    const formattedDate = date.format('YYYY-MM-DD');  // Converts it back to 'YYYY-MM-DD' for consistency in data storage
                                                    setStoreInvoice({
                                                        ...storeInvoice,
                                                        invoice_date: formattedDate,
                                                    });
                                                }}
                                            />

                                        </LocalizationProvider>
                                    </FormControl>
                                    <FormControl fullWidth style={PO.vendor_id !== storeInvoice.vendor_id ? {
                                        marginBottom: '1rem',
                                        backgroundColor: 'lightyellow'
                                    } : { marginBottom: '1rem' }}>
                                        {/* <InputLabel id="demo-simple-select-required-label">Vendor</InputLabel> */}
                                        <Autocomplete
                                            value={vendorOption.find(option => option.value === storeInvoice?.vendor_id)}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setSellerName(newValue.label);
                                                    setStoreInvoice({
                                                        ...storeInvoice,
                                                        vendor_id: newValue.value,
                                                    });
                                                }
                                            }}
                                            options={vendorOption}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Vendor"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                            fullWidth
                                        />

                                    </FormControl>

                                    <FormControl fullWidth style={PO.po_amount !== invoice.invoice_amount ? {
                                        marginBottom: '1rem',
                                        backgroundColor: 'lightyellow'
                                    } : { marginBottom: '1rem' }}>
                                        <NumberFormat
                                            disabled={invoice.status === "posted" || invoice.status === "paid"}
                                            sx={sxTemp}
                                            label="Total Amount"
                                            customInput={TextField}
                                            thousandSeparator={navigator.languages[0] === "en-US" ? "," : "."}
                                            decimalSeparator={navigator.languages[0] === "en-US" ? "." : ","}
                                            isNumericString
                                            fixedDecimalScale
                                            decimalScale={2}
                                            prefix="$"
                                            error={invoice.invoice_amount === null || invoice.invoice_amount === 0 || invoice.invoice_amount === ''}
                                            helperText={invoice.invoice_amount === null || invoice.invoice_amount === 0 || invoice.invoice_amount === '' ? "Total Amount cannot be empty" : ""}
                                            value={invoice.invoice_amount}
                                            InputProps={{
                                                inputProps: { style: { textAlign: 'end' } },
                                            }}
                                            onValueChange={(values) => {
                                                const { value } = values;
                                                setInvoice({
                                                    ...invoice,
                                                    invoice_amount: value,
                                                })
                                            }}
                                            allowNegative={false}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth style={PO.gl_account !== storeInvoice.client_number ? {
                                        marginBottom: '1rem',
                                        backgroundColor: 'lightyellow'
                                    } : { marginBottom: '1rem' }}>
                                        <Autocomplete
                                            disabled={invoice.status === "posted" || invoice.status === "paid"}
                                            id="client_number-select"
                                            options={accountNumberOption}
                                            renderInput={(params) => <TextField {...params}
                                                error={storeInvoice.client_number === 0 || storeInvoice.client_number === null || storeInvoice.client_number === undefined}
                                                helperText={storeInvoice.client_number === 0 || storeInvoice.client_number === "" || storeInvoice.client_number === undefined ? "GL Account cannot be empty" : ""}
                                                label="GL Account" />}
                                            value={storeInvoice.client_number}
                                            onChange={(event, newValue) => {
                                                setStoreInvoice({
                                                    ...storeInvoice,
                                                    client_number: newValue.value,
                                                })
                                            }}
                                        />
                                    </FormControl>

                                    <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                                        <Row className={"align-items-center"} fullWidth>
                                            <Col sm={(invoice?.status === "approvement" || invoice?.status === "failed") ? "10" : "12"}>
                                                <Autocomplete
                                                    disabled={invoice.status === "posted" || invoice.status === "paid"}
                                                    id="po_number-select"
                                                    options={poItemsOption}
                                                    renderInput={(params) => <TextField {...params}
                                                        label="PO #" />}
                                                    value={storeInvoice.po_number}
                                                    onChange={(event, newValue) => {
                                                        setStoreInvoice({
                                                            ...storeInvoice,
                                                            po_number: newValue.value,
                                                        })
                                                    }}
                                                />
                                            </Col>
                                            <Col sm="2">
                                                {(invoice?.status === "approvement" || invoice?.status === "failed") &&
                                                    <button disabled={false} className="btn btn-primary"
                                                        onClick={() => {
                                                            getPODetail()
                                                        }}
                                                        style={{ float: 'right' }}>
                                                        Go <ArrowRight style={{ display: "inline" }} />
                                                    </button>
                                                }
                                            </Col>
                                            <Col style={{
                                                paddingTop: 20
                                            }}>
                                                {parseFloat(totalBudgetAllocation) !== parseFloat(invoice.invoice_amount) &&
                                                    <FormControl fullWidth style={{
                                                        marginBottom: '1rem',
                                                        backgroundColor: 'lightyellow',
                                                    }}>
                                                        * Total amount should match the sum of line item amounts.
                                                    </FormControl>
                                                }
                                            </Col>
                                        </Row>
                                    </FormControl>

                                </CardContent>
                            </Card>
                        </Col>
                        <Col>
                            {poDetailLoading &&
                                <div>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="lg"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Loading      Header ...
                                </div>
                            }
                            {!poDetailLoading &&

                                <Card sx={{ width: '100%', height: '100%' }}>
                                    <CardContent>
                                        {PO?.length !== 0 &&
                                            <Typography gutterBottom variant="h6" component="div">
                                                PO Header
                                            </Typography>
                                        }
                                        {poNotFound &&
                                            < Typography gutterBottom variant="h6" component="div"
                                                style={{ backgroundColor: "yellow" }}>
                                                No PO found with the given PO number.
                                            </Typography>
                                        }
                                        {poEmpty &&
                                            < Typography gutterBottom variant="h6" component="div"
                                                style={{ backgroundColor: "yellow" }}>
                                                No PO number given.
                                            </Typography>
                                        }
                                        <hr className="fs-1" />
                                        <FormControl fullWidth style={{ marginBottom: '1rem', marginTop: '4.5rem' }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label={
                                                        <span style={{ color: "#A6A6A6" }}>PO Date</span>
                                                    }
                                                    startDate={dayjs(PO.po_date)}
                                                    format={PO.po_date !== '' ? "MM-DD-YYYY" : ""}
                                                    value={dayjs(PO.po_date)}
                                                    sx={sxTemp}
                                                    disabled={true}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                        <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                                            <TextField

                                                label={
                                                    <span>Vendor</span>}
                                                value={PO.vendor_id + ' - ' + PO.supplier_name}
                                                sx={sxTemp}
                                                disabled={true}

                                                onChange={(e) => {
                                                    setPO({
                                                        ...PO,
                                                        supplier_name: e.target.value,
                                                    })
                                                }} />
                                        </FormControl>

                                        <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                                            <NumberFormat

                                                label="Total Amount"
                                                customInput={TextField}
                                                thousandSeparator={navigator.languages[0] === "en-US" ? "," : "."}
                                                decimalSeparator={navigator.languages[0] === "en-US" ? "." : ","}
                                                isNumericString
                                                fixedDecimalScale
                                                decimalScale={2}
                                                prefix="$"
                                                value={PO.po_amount}
                                                InputProps={{
                                                    inputProps: { style: { textAlign: 'end' } },
                                                }}
                                                sx={sxTemp}
                                                disabled
                                                allowNegative={false}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                                            <TextField

                                                label={
                                                    <span>GL Account</span>}
                                                value={PO.gl_account}
                                                sx={sxTemp}
                                                disabled
                                                onChange={(e) => {
                                                    setPO({
                                                        ...PO,
                                                        gl_account: e.target.value,
                                                    })
                                                }} />
                                        </FormControl>
                                        <div className="flex flex-row space-x-2">
                                            <FormControl style={{ marginBottom: '1rem' }}>
                                                <TextField

                                                    label={
                                                        <span>PO #</span>}
                                                    value={PO.po_number}
                                                    sx={sxTemp}
                                                    disabled
                                                    onChange={(e) => {
                                                        setPO({
                                                            ...PO,
                                                            po_number: e.target.value,
                                                        })
                                                    }} />
                                            </FormControl>
                                            <FormControl className="w-full" style={{ marginBottom: '1rem' }}>
                                                <TextField

                                                    label={
                                                        <span>PO Note</span>}
                                                    value={PO.po_note}
                                                    sx={sxTemp}
                                                    disabled
                                                    onChange={(e) => {
                                                        setPO({
                                                            ...PO,
                                                            po_note: e.target.value,
                                                        })
                                                    }} />
                                            </FormControl>
                                        </div>
                                    </CardContent>
                                </Card>
                            }
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col sm="6">
                            <Card sx={{ width: '100%', height: '100%' }}>
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="div">
                                        Invoice Detail
                                    </Typography>
                                    <hr className="fs-1" />

                                    {invoice?.line_items?.map((source, index) => (
                                        <React.Fragment key={index}>
                                            <Grid container alignItems="center" spacing={2}>
                                                <Grid item xs={8}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <h6>{`Line Item ${index + 1}`}</h6>
                                                        {(invoice?.status === "approvement" || invoice?.status === "failed") && <IconButton
                                                            size="small"
                                                            disabled={invoice.status === "posted" || invoice.status === "paid"}
                                                            onClick={() => handleRemoveLineItem(index)}
                                                            aria-label="Remove"
                                                            style={{
                                                                marginTop: '-5px',
                                                                color: 'red'
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                        }
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <br></br>

                                            <Grid item xs={6}>
                                                <Row>
                                                    <Col xl={8}>
                                                        <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                                                            <TextField
                                                                error={invoice?.line_items[index].description === null || invoice?.line_items[index].description === ""}
                                                                helperText={invoice?.line_items[index].description === null || invoice?.line_items[index].description === "" ? "Description cannot be empty" : ""}
                                                                disabled={invoice.status === "posted" || invoice.status === "paid"}
                                                                sx={sxTemp}
                                                                label={'Description'}
                                                                value={invoice?.line_items[index].description ? invoice?.line_items[index].description : ''}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    const updatedFundingSources = [...invoice.line_items];
                                                                    updatedFundingSources[index].description = value;
                                                                    setInvoice({
                                                                        ...invoice,
                                                                        line_items: updatedFundingSources
                                                                    });
                                                                }}
                                                            />

                                                        </FormControl>
                                                    </Col>
                                                    <Col xl={4}>
                                                        <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                                                            <InputLabel id={`poline-select-label-${index}`}>Matching PO Line</InputLabel>
                                                            <Select
                                                                labelId={`matching-po-line-${index}`}
                                                                label={"Matching PO Line"}
                                                                id={`matching-po-line-${index}`}
                                                                value={invoice?.line_items[index].matching ?? ''}
                                                                disabled={invoice.status === "posted" || invoice.status === "paid"}
                                                                sx={sxTemp}
                                                                onChange={(e) => {
                                                                    const updatedLineItems = [...invoice.line_items];
                                                                    const selectedIndex = e.target.value;  // Get the selected index

                                                                    if (selectedIndex === null) {
                                                                        // Clear logic
                                                                        updatedLineItems[index].matching = '';  // Clear the matching selection
                                                                        updatedLineItems[index].description = '';  // Reset description
                                                                        updatedLineItems[index].rec_dat = '';  // Reset received date
                                                                        updatedLineItems[index].is_poitem = false;
                                                                        const updatedMappings = mappings.filter((mapping) => mapping.inv !== index);

                                                                        setMappings(updatedMappings);
                                                                    } else {
                                                                        // Update logic when an option is selected
                                                                        updatedLineItems[index].matching = selectedIndex;  // Store the index as matching_po_line
                                                                        updatedLineItems[index].description = POItems[selectedIndex].product_description;
                                                                        updatedLineItems[index].rec_dat = POItems[selectedIndex].pr_rec_dat;
                                                                        updatedLineItems[index].po_cost = POItems[selectedIndex].line_price
                                                                        updatedLineItems[index].is_poitem = true;
                                                                        const marking = { line: POItems[selectedIndex].pr_line_nu, rec: POItems[selectedIndex].pr_rec_num };
                                                                        setMarkings([...markings, marking]);
                                                                        const mapping = { inv: index, po: selectedIndex };
                                                                        setMappings([...mappings, mapping]);
                                                                    }

                                                                    // Update the invoice state
                                                                    setInvoice({
                                                                        ...invoice,
                                                                        line_items: updatedLineItems
                                                                    });
                                                                }}
                                                            >
                                                                <MenuItem value={null}>Clear</MenuItem>  {/* Clear option */}

                                                                {/* Map through POItems and use the index as value */}
                                                                {POItems.map((option, idx) => (
                                                                    <MenuItem key={idx} value={idx}>
                                                                        Line#{option.line_number} Received#{option.pr_rec_num}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>

                                                    </Col>
                                                </Row>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Row>
                                                    <Col xl={4}>
                                                        <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                                                            <Autocomplete
                                                                disabled={invoice.status === "posted" || invoice.status === "paid"}
                                                                id="client_number-select"
                                                                options={accountNumberOption}
                                                                renderInput={(params) => <TextField {...params}
                                                                    error={invoice?.line_items[index].acc_number === 0 || invoice?.line_items[index].acc_number === null || invoice?.line_items[index].acc_number === undefined}
                                                                    helperText={invoice?.line_items[index].acc_number === 0 || invoice?.line_items[index].acc_number === null || invoice?.line_items[index].acc_number === undefined ? "GL Account cannot be empty" : ""}
                                                                    label="GL Account" />}
                                                                value={invoice?.line_items[index].acc_number}
                                                                onChange={(event, newValue) => {
                                                                    const value = newValue.value;
                                                                    const updatedFundingSources = [...invoice.line_items];
                                                                    updatedFundingSources[index].acc_number = value;
                                                                    setInvoice({
                                                                        ...invoice,
                                                                        line_items: updatedFundingSources
                                                                    });
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xl={2}>
                                                        <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                                                            <NumberFormat
                                                                error={isNaN(invoice?.line_items[index].quantity) || invoice?.line_items[index].quantity === 0 || invoice?.line_items[index].quantity === null || invoice?.line_items[index].quantity === ''}
                                                                helperText={isNaN(invoice?.line_items[index].quantity) || invoice?.line_items[index].quantity === 0 || invoice?.line_items[index].quantity === null || invoice?.line_items[index].quantity === '' ? "Quantity cannot be empty" : ""}
                                                                disabled={invoice.status === "posted" || invoice.status === "paid"}
                                                                sx={sxTemp}
                                                                isNumericString
                                                                label="Quantity"
                                                                customInput={TextField}
                                                                value={invoice.line_items[index].quantity}
                                                                InputProps={{
                                                                    inputProps: { style: { textAlign: 'end' } },
                                                                }}
                                                                onValueChange={(values) => {
                                                                    const { value } = values;
                                                                    let updated = [...invoice.line_items];
                                                                    updated[index].quantity = parseFloat(value);
                                                                    updated[index].amount = index > invLen - 1 ? parseFloat(value * invoice.line_items[index].unit_price) : invItems[index].line_price;
                                                                    updated[index].line_price = index > invLen - 1 ? parseFloat(value * invoice.line_items[index].unit_price) : invItems[index].line_price;
                                                                    setInvoice({
                                                                        ...invoice,
                                                                        line_items: updated
                                                                    })
                                                                }}
                                                                allowNegative={false}
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xl={3}>
                                                        <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                                                            <NumberFormat
                                                                error={invoice?.line_items[index].unit_price === undefined || invoice?.line_items[index].unit_price === 0 || invoice?.line_items[index].unit_price === null || invoice?.line_items[index].unit_price === ''}
                                                                helperText={invoice?.line_items[index].unit_price === undefined || invoice?.line_items[index].unit_price === 0 || invoice?.line_items[index].unit_price === null || invoice?.line_items[index].unit_price === '' ? "Unit Price cannot be empty" : ""}
                                                                disabled={invoice.status === "posted" || invoice.status === "paid"}
                                                                sx={sxTemp}
                                                                label="Unit Amount"
                                                                customInput={TextField}
                                                                thousandSeparator={navigator.languages[0] === "en-US" ? "," : "."}
                                                                decimalSeparator={navigator.languages[0] === "en-US" ? "." : ","}
                                                                isNumericString
                                                                fixedDecimalScale
                                                                decimalScale={2}
                                                                prefix="$"
                                                                value={invoice.line_items[index].unit_price}
                                                                InputProps={{
                                                                    inputProps: { style: { textAlign: 'end' } },
                                                                }}
                                                                onValueChange={(values) => {
                                                                    const { value } = values;
                                                                    let updated = [...invoice.line_items];
                                                                    updated[index].unit_price = value;
                                                                    // updated[index].line_price = value;
                                                                    updated[index].amount = index > invLen - 1 ? value * invoice.line_items[index].quantity : invItems[index].line_price;
                                                                    updated[index].line_price = index > invLen - 1 ? value * invoice.line_items[index].quantity : invItems[index].line_price;
                                                                    setInvoice({
                                                                        ...invoice,
                                                                        line_items: updated
                                                                    })
                                                                }}
                                                                allowNegative={false}
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xl={3}>
                                                        <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                                                            <NumberFormat

                                                                label="Extended Amount"
                                                                customInput={TextField}
                                                                thousandSeparator={navigator.languages[0] === "en-US" ? "," : "."}
                                                                decimalSeparator={navigator.languages[0] === "en-US" ? "." : ","}
                                                                decimalScale={2}
                                                                fixedDecimalScale
                                                                sx={sxTemp}
                                                                
                                                                prefix="$"
                                                                value={invoice.line_items[index].line_price}
                                                                InputProps={{
                                                                    inputProps: { style: { textAlign: 'end' } },
                                                                }}
                                                                onValueChange={(values) => {
                                                                    const { value } = values;
                                                                    let updated = [...invoice.line_items];
                                                                    updated[index].line_price = value;
                                                                    setInvoice({
                                                                        ...invoice,
                                                                        line_items: updated
                                                                    })
                                                                }}
                                                                allowNegative={false}
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                            </Grid>

                                            <hr className="fs-1" />
                                        </React.Fragment>
                                    ))}

                                    {(invoice?.status === "approvement" || invoice?.status === "failed") && <Grid item xs={12}>
                                        <Grid item xs={2}>
                                            <Button variant="primary" onClick={handleAddLineItem} fullWidth>
                                                Add Item
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    }

                                </CardContent>
                            </Card>
                        </Col>
                        <Col sm="6">
                            <Card sx={{ width: '100%', height: '100%' }}>
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="div">
                                        PO Detail
                                    </Typography>
                                    <hr className="fs-1" />
                                    {(POItems.length === 0) && <h1 className="text-sm text-red-600">No Purchase Order Item Received Yet.</h1>}

                                    {poDetailLoading &&
                                        <div>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="lg"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Loading PO Items ...
                                        </div>
                                    }
                                    {
                                        !poDetailLoading &&
                                        <>
                                            {POItems?.map((source, index) => (
                                                <React.Fragment key={index}>
                                                    <Grid container alignItems="center" spacing={2}>
                                                        <Grid item xs={16}>
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                                                                <p className="font-bold">{`PO Line Item ${POItems[index].line_number}`}</p><p>{`(Received Item ${POItems[index].pr_rec_num})`}</p>
                                                                {markings.some(
                                                                    (marking) =>
                                                                        marking.line === source.line_number &&
                                                                        marking.rec === source.pr_rec_num
                                                                ) && (
                                                                        <CheckCircleIcon style={{ color: 'green', position: 'relative', top: '-8px' }} />
                                                                    )}
                                                            </div>
                                                            <div className="w-full">
                                                                {mappings.find(
                                                                    (mapping) => mapping.po === index
                                                                ) && !arePricesEqual(
                                                                    invoice.line_items[mappings.find((mapping) => mapping.po === index)?.inv]?.unit_price || 'N/A',
                                                                    POItems[mappings.find((mapping) => mapping.po === index)?.po]?.unit_price || 'N/A'
                                                                  )
                                                                    && (
                                                                        <div className="flex flex-row">
                                                                            <input
                                                                                type="checkbox"
                                                                                id="updatePrice"
                                                                                className="mr-2"
                                                                                onChange={(e) =>
                                                                                    handleCheckboxChange(
                                                                                        e,
                                                                                        mappings.find((mapping) => mapping.po === index)?.inv,
                                                                                        POItems[mappings.find((mapping) => mapping.po === index)?.po]?.product_description,
                                                                                        POItems[mappings.find((mapping) => mapping.po === index)?.po]?.unit_price
                                                                                    )
                                                                                }
                                                                            />
                                                                            <p className="w-full font-bold text-red-700 mt-3">
                                                                                Update Unit Price in VB to match Invoice amount from ${POItems[mappings.find((mapping) => mapping.po === index)?.po]?.unit_price} to $
                                                                                {invoice.line_items[mappings.find((mapping) => mapping.po === index)?.inv]?.unit_price} ?
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                            </div>



                                                        </Grid>
                                                    </Grid>
                                                    <br></br>
                                                    <Grid item xs={6}>
                                                        <Row>
                                                            <Col xl={9}>
                                                                <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                                                                    <TextField
                                                                        sx={sxTemp}
                                                                        disabled
                                                                        label={'Description'}
                                                                        value={POItems[index].product_description}
                                                                    />
                                                                </FormControl>
                                                            </Col>
                                                            <Col xl={3}>
                                                                <FormControl
                                                                    fullWidth
                                                                    style={{ marginBottom: '1rem' }}
                                                                >
                                                                    <TextField
                                                                        label="Receive Date"
                                                                        type="date"
                                                                        value={POItems[index].pr_rec_dat}
                                                                        sx={sxTemp}
                                                                        disabled
                                                                        InputProps={{
                                                                            inputProps: {
                                                                                style: { textAlign: 'end' }  /* Custom styles */
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Col>
                                                        </Row>

                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Row>
                                                            <Col xl={4}>
                                                                <Row>
                                                                    <Col xl={6}>
                                                                        <FormControl fullWidth
                                                                            style={{ marginBottom: '1rem' }}>
                                                                            <NumberFormat
                                                                                label="Quantity"
                                                                                customInput={TextField}
                                                                                value={POItems[index].pr_qty_rec}
                                                                                InputProps={{
                                                                                    inputProps: { style: { textAlign: 'end' } },
                                                                                }}
                                                                                sx={sxTemp}
                                                                                disabled
                                                                                allowNegative={false}
                                                                            />
                                                                        </FormControl>
                                                                    </Col>
                                                                    <Col xl={6}>
                                                                        <FormControl fullWidth
                                                                            style={{ marginBottom: '1rem' }}>
                                                                            <NumberFormat
                                                                                label="Shipped Qty"
                                                                                customInput={TextField}
                                                                                value={POItems[index].quantity_shipped ? POItems[index].quantity_shipped : 0}
                                                                                InputProps={{
                                                                                    inputProps: { style: { textAlign: 'end' } },
                                                                                }}
                                                                                sx={sxTemp}
                                                                                disabled
                                                                                allowNegative={false}
                                                                            />
                                                                        </FormControl>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col xl={2}>
                                                                <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                                                                    <TextField

                                                                        sx={sxTemp}
                                                                        disabled
                                                                        label={'Unit Type'}
                                                                        value={POItems[index].unit_type}
                                                                    />
                                                                </FormControl>
                                                            </Col>
                                                            <Col xl={3}>
                                                                <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                                                                    <NumberFormat

                                                                        label="Unit Amount"
                                                                        customInput={TextField}
                                                                        thousandSeparator={navigator.languages[0] === "en-US" ? "," : "."}
                                                                        decimalSeparator={navigator.languages[0] === "en-US" ? "." : ","}
                                                                        isNumericString
                                                                        fixedDecimalScale
                                                                        decimalScale={2}
                                                                        prefix="$"
                                                                        value={POItems[index].unit_price}
                                                                        InputProps={{
                                                                            inputProps: { style: { textAlign: 'end' } },
                                                                        }}
                                                                        sx={sxTemp}
                                                                        disabled
                                                                        allowNegative={false}
                                                                    />
                                                                </FormControl>
                                                            </Col>
                                                            <Col xl={3}>
                                                                <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                                                                    <NumberFormat

                                                                        label="Extended Amount"
                                                                        customInput={TextField}
                                                                        thousandSeparator={navigator.languages[0] === "en-US" ? "," : "."}
                                                                        decimalSeparator={navigator.languages[0] === "en-US" ? "." : ","}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale
                                                                        sx={sxTemp}
                                                                        disabled
                                                                        prefix="$"
                                                                        value={POItems[index].unit_price * POItems[index].pr_qty_rec}
                                                                        InputProps={{
                                                                            inputProps: { style: { textAlign: 'end' } },
                                                                        }}
                                                                        allowNegative={false}
                                                                    />
                                                                </FormControl>
                                                            </Col>
                                                        </Row>


                                                    </Grid>
                                                    <hr className="fs-1" />
                                                </React.Fragment>
                                            ))}
                                        </>
                                    }
                                </CardContent>
                            </Card>
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col>
                            {!poDetailLoading &&
                                <> <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                    >
                                        <Typography><TopicIcon></TopicIcon> Invoice PDF </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography> <a target="_blank"
                                            href={invoice.download_link}><OpenInNewIcon></OpenInNewIcon>Open
                                            Link </a></Typography>
                                        <br></br>
                                        {(invoice.download_link === undefined || invoice.download_link === '' || invoice.download_link === null) ?
                                            <Typography>The invoice has not a pdf document.</Typography> :
                                            <object width="100%" height="1400px" data={invoice.download_link}
                                                type="application/pdf"></object>

                                        }

                                    </AccordionDetails>
                                </Accordion>
                                </>
                            }
                        </Col>
                    </Row>

                    <br></br>
                    {!loadingInvoice && (invoice.status === 'approvement' || invoice.status === 'failed') &&
                        <Grid item xs={12} container justifyContent="flex-end">
                            {
                                !loadingSubmit && (
                                    <Button variant="primary"
                                        sx={sxTemp}
                                        disabled={
                                            isNaN(parseFloat(storeInvoice.invoice_amount)) ||
                                            dayjs(storeInvoice.invoice_date, 'MM-DD-YYYY').isBefore(dayjs(), 'day') ||
                                            isEmpty(storeInvoice.invoice_number) ||
                                            isEmpty(storeInvoice.invoice_date) ||
                                            isNaN(storeInvoice.vendor_id) ||
                                            isEmpty(storeInvoice.client_number) ||
                                            invoice.line_items?.length === 0 ||
                                            invoice.line_items?.some(
                                                (item) =>
                                                    isNaN(parseFloat(item.unit_price)) ||
                                                    isNaN(parseFloat(item.quantity))
                                            ) ||
                                            parseFloat(totalBudgetAllocation) !== parseFloat(invoice.invoice_amount)

                                        }
                                        onClick={async () => {
                                            if (invoicesToUpdate.length === 0) {
                                                await submit();
                                            }
                                            else {
                                                handleSubmitPopupOpen()
                                            }
                                        }}>
                                        Post
                                    </Button>)}
                            {
                                loadingSubmit && (
                                    <Button variant="contained" sx={sxTemp}
                                        disabled>
                                        <Spinner as="span" animation="grow" size="lg" role="status" aria-hidden="true" />
                                        Submitting...
                                    </Button>
                                )}
                            {
                                !loadingSave && (
                                    <Button variant="success"
                                        className={"ms-1"}
                                        onClick={async () => {
                                            await save();
                                        }}>
                                        Save
                                    </Button>)
                            }
                            {
                                loadingSave && (
                                    <Button variant="success" sx={sxTemp}
                                        disabled
                                        className={"ms-1"}>
                                        <Spinner as="span" animation="grow" size="lg" role="status" aria-hidden="true" />
                                        Saving...
                                    </Button>
                                )}
                            <Button variant="danger"
                                className={"ms-1"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowDeletionPopup(true);
                                }}>
                                Delete
                            </Button>
                            <Button style={{ backgroundColor: "gray" }}
                                className={"ms-1"}
                                onClick={(e) => {
                                    window.close()
                                }}>
                                Close
                            </Button>
                        </Grid>
                    }
                </div>

            }

            {
                showDeletionPopup &&
                <DeleteInvoicePopup show={true} closePopup={setShowDeletionPopup}
                    invoice_id={routeParams.id}
                    reject_inovice_callback={deleteInvoice} />
            }

            <SummaryDialog
                open={open}
                handleClose={handleClose}
                invoicesToBeUpdated={invoicesToUpdate}
                handleConfirm={handleConfirm}
            />


            <Footer />
        </>
    )
}

export default InvoiceDetail;